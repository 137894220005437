import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner'; // Import the spinner component
import './ForgotPasswordPage.css';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false); // Track if reset code was sent
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true before request
    setEmail(email.toLowerCase());

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, { email });
      setMessage('Password reset code has been sent to your email.');
      setError('');
      setResetSent(true); // Mark reset as sent
    } catch (err) {
      setError('Email does not exist in our system. Please try again.');
      setMessage('');
      console.error('Reset password error:', err);
    } finally {
      setLoading(false); // Set loading state to false after request completes
    }
  };

  return (
    <div className="center-container-forgot">
      <div className="forgot-password-container">
        <h1>Forgot Password</h1>
        {message && <p className="message">{message}</p>}
        {error && <p className="error">{error}</p>}
        {!resetSent ? (
          <form onSubmit={handleSubmit} className="form-container">
            <input classname='_input'type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <button type="submit">Send Reset Code</button>
          </form>
        ) : (
          <div className="form-container">
            <button onClick={() => navigate('/reset-password')} className="goto-reset-button">
              Enter Reset Code
            </button>
          </div>
        )}
        {loading && <Spinner />} {/* Conditionally render the spinner */}
        <button onClick={() => navigate('/')} className="back-button"> {/* Add the Back button */}
          Back to Login
        </button>
        <a href="https://www.dynamiq.com/contact" target="_blank" rel="noopener noreferrer" className="need-help-link">Need Help?</a>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
