import React from 'react';
import './SigmaReport.css';

const SigmaReport = ({ embedUrl }) => {
  console.log('Rendering SigmaReport with URL:', embedUrl);
  return (
    <div>
      <iframe
        className='report'
        src={embedUrl}
        title="Sigma Report"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
};

export default SigmaReport;