import React, { useState, useEffect } from 'react';
import logo from '../assets/images/dynamiq-logo.png';
import './Nav.css';

const Nav = ({ reports, handleReportSelect, isLoggedIn, handleLogout, navigateToDashboard }) => {
  const initialReportId = reports.find(report => report.name === 'Dashboard')?.id || reports[0]?.id;
  const [selectedReport, setSelectedReport] = useState(initialReportId);
  const [navVisible, setNavVisible] = useState(true);

  useEffect(() => {
    if (initialReportId && selectedReport === null) {
      const dashboardReport = reports.find(report => report.id === initialReportId);
      if (dashboardReport) {
        setSelectedReport(dashboardReport.id);
        handleReportSelect(dashboardReport);
      }
    }
  }, [reports, handleReportSelect, initialReportId]);

  const toggleNavVisibility = () => {
    setNavVisible(!navVisible);
  };

  const handleOnClick = (report) => {
    setSelectedReport(report.id);
    handleReportSelect(report);
  };

  // Separating special reports and other reports
  const specialReports = ['Utilities', 'Analytics'];
  const normalReports = reports.filter(report => !specialReports.includes(report.name));
  const specialGroupReports = reports.filter(report => specialReports.includes(report.name));

  const groupedReports = normalReports.reduce((groups, report) => {
    const group = report.group || 'General';
    groups[group] = groups[group] || [];
    groups[group].push(report);
    return groups;
  }, {});

  const selectDashboard = () => {
    const dashboardReport = reports.find(report => report.name === 'Dashboard');
    if (dashboardReport) {
      handleOnClick(dashboardReport);
    }
  };

  return (
    <div className={`nav-container ${navVisible ? 'nav-visible' : 'nav-hidden'}`}>
      <button onClick={toggleNavVisibility} className={`nav-toggle-button ${navVisible ? 'nav-visible' : 'nav-hidden'}`}></button>
      <img src={logo} alt="Dynamiq Logo" className="dynamiq-logo" onClick={selectDashboard}/>
      <ul className="report-list">
        {Object.entries(groupedReports)
          .map(([groupName, groupReports]) => (
            groupName === 'Work History' ? (
              <li key={groupName} className="group-header">
                <span>{groupName}</span>
                <ul className="sub-report-list">
                  {groupReports.map(report => (
                    <li key={report.id} className={selectedReport === report.id ? 'sub-selected' : ''} onClick={() => handleOnClick(report)}>
                      {report.name}
                    </li>
                  ))}
                </ul>
              </li>
            ) : groupReports.map(report => (
              <li key={report.id} className={selectedReport === report.id ? 'selected' : ''} onClick={() => handleOnClick(report)}>
                {report.name}
              </li>
            ))
          ))
        }
        {specialGroupReports.map(report => (
          <li key={report.id} className={selectedReport === report.id ? 'selected' : ''} onClick={() => handleOnClick(report)}>
            {report.name}
            <span className="coming-soon">
              {report.name === 'Analytics' ? 'Coming Soon' : 'Coming Soon'}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Nav;
