import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner'; // Import the spinner component
import './ResetPasswordPage.css';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const navigate = useNavigate();

  const isValidPassword = (password) => {
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    return password.length >= 5 && hasLowercase && hasUppercase && hasNumber;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    if (!isValidPassword(newPassword)) {
      setError("Password must be at least 5 characters long and include at least one lowercase letter, one uppercase letter, and one number.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/reset-password/verify`, {
        email,
        code,
        newPassword,
      });
      setMessage('Password has been reset. You can now log in with your new password.');
      setError('');
      setDisableForm(true);
      setTimeout(() => navigate('/'), 3000);
    } catch (err) {
      setError('Invalid email or reset code. Please try again.');
      console.error('Reset password error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="center-container-reset">
      <div className="reset-password-container">
        <h1 className='h1'>Reset Password</h1>
        {message && <p className="message">{message}</p>}
        {error && <p className="error">{error}</p>}
        {!disableForm && (
          <form onSubmit={handleSubmit} className="form-container">
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required disabled={disableForm} />
            <input type="text" placeholder="Reset Code" value={code} onChange={(e) => setCode(e.target.value)} required disabled={disableForm} />
            <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required disabled={disableForm} />
            <ul className="password-requirements">
              <li className={/[a-z]/.test(newPassword) ? 'valid' : 'invalid'}>Lowercase letter</li>
              <li className={/[A-Z]/.test(newPassword) ? 'valid' : 'invalid'}>Uppercase letter</li>
              <li className={/[0-9]/.test(newPassword) ? 'valid' : 'invalid'}>Number</li>
            </ul>
            <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required disabled={disableForm} />
            <button className='button-reset' type="submit" disabled={loading || disableForm}>
              {loading ? 'Resetting...' : 'Reset Password'}
            </button>
            <a href="https://www.dynamiq.com/contact" target="_blank" rel="noopener noreferrer" className="need-help-link">Need Help?</a>
          </form>
        )}
        {loading && <Spinner />}
        {!disableForm && (
          <button onClick={() => navigate('/')} className="back-button">
            Back to Login
          </button>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
