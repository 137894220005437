import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="floating-footer">
      <p>&copy; {new Date().getFullYear()} DYNAMIQ Reporting. A Total Comfort Solutions Brand. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
