import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './SignupForm.css';

const SignupForm = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [properties, setProperties] = useState([]);
  const [availableProperties, setAvailableProperties] = useState([]);
  const [propertyIds, setPropertyIds] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sigma/customers`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log("Fetched customers response:", response.data);

        if (response.data.customer_names && response.data.customer_ids) {
          setCustomers(response.data.customer_names.map((customer, index) => ({
            id: response.data.customer_ids[index], // Use the actual customer ID
            name: customer
          })));
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const handleCustomerChange = async (id) => {
    const selectedCustomer = customers.find(customer => customer.id === id);
    if (selectedCustomer) {
      setCustomerId(id);
      setCustomerName(selectedCustomer.name);
      setSearchText(selectedCustomer.name);
      setDropdownOpen(false);
      setProperties([]);
      setPropertyIds([]);
      console.log("Selected customer ID: " + selectedCustomer.id);
  
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sigma/buildings/${selectedCustomer.id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
  
        console.log("Fetched properties response:", response.data);
  
        // Assuming the response structure is { property_names: [...], property_ids: [...] }
        if (response.data.property_names && response.data.property_ids) {
          const propertiesWithIds = response.data.property_names.map((name, index) => ({
            name,
            id: response.data.property_ids[index],
            property_type: response.data.property_type[index]
          }));
          setAvailableProperties(propertiesWithIds);
        } else {
          console.error('Unexpected properties response structure:', response.data);
          setAvailableProperties([]);
        }
      } catch (error) {
        console.error('Failed to fetch properties:', error);
        setAvailableProperties([]);
      }
    }
  };

  const handlePropertyChange = (property) => {
    setProperties((prevProperties) => {
      if (prevProperties.includes(property.name)) {
        setPropertyIds(prevIds => prevIds.filter(id => id !== property.id));
        return prevProperties.filter(p => p !== property.name);
      } else {
        setPropertyIds(prevIds => [...prevIds, property.id]);
        return [...prevProperties, property.name];
      }
    });
  };

  const handleSelectAllChange = () => {
    if (properties.length === availableProperties.length) {
      // Deselect all properties
      setProperties([]);
      setPropertyIds([]);
    } else {
      // Select all properties
      setProperties(availableProperties.map(property => property.name));
      setPropertyIds(availableProperties.map(property => property.id));
    }
  };

  const isAllSelected = properties.length === availableProperties.length && properties.length > 0;

  const shouldHideCustomerAndProperties = email.endsWith('@totalcomfortsolutions.com') || email.endsWith('@dynamiq.com');

  const generateTempPassword = (length = 12) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:,.<>?";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    const tempPassword = generateTempPassword();
    const buildops_customer_id = customerId;

    let data = {
      name: name.toLowerCase(),
      email: email.toLowerCase(),
      password: tempPassword,
      customer: customerName,
      properties,
      buildops_property_ids: propertyIds,
      buildops_customer_id
    };

    const jsonString = JSON.stringify(data);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/signup`, jsonString, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' 
        }
      });

      setSuccess('User created successfully!');
      setError('');
      setIsSignupSuccessful(true);
      setShowSuccessPopup(true);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 422 && err.response.data.error === "User already exists.") {
          setError('User with this email already exists.');
        } else {
          setError('Failed to create user. Please try again.');
        }
      } else {
        setError('Failed to create user. Please try again.');
      }
      console.error('Signup error:', err);
    }
  };

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="overlay">
      <div className="signup-form-modal">
        <h2>Create New User</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {!shouldHideCustomerAndProperties && (
            <>
              <div className="dropdown-container" ref={dropdownRef}>
                <input
                  type="text"
                  placeholder="Search Customer"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown open/close on click
                  className="dropdown-input"
                />
                {dropdownOpen && (
                  <div className="dropdown-menu">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort customers by name alphabetically
                        .map((customer) => (
                          <div
                            key={customer.id} // Ensure this ID is unique
                            className="dropdown-item"
                            onClick={() => handleCustomerChange(customer.id)} // Use the actual customer ID
                          >
                            {customer.name}
                          </div>
                        ))
                    ) : (
                      <div className="dropdown-item">No customers found</div>
                    )}
                  </div>
                )}
              </div>

              <div className="properties-section">
                <h3>Select Properties</h3>
                <div className="properties-checkboxes">
                  {availableProperties.length > 0 && (
                    <label className="property-checkbox-signup">
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleSelectAllChange}
                      />
                      Select All
                    </label>
                  )}

                  {availableProperties.length > 0 ? (
                    availableProperties
                      .sort((a, b) => a.property_type.localeCompare(b.property_type)) // Sort alphabetically by property type
                      .map((property) => (
                        <label key={property.id} className="property-checkbox-signup">
                          <input
                            type="checkbox"
                            value={property.name}
                            checked={properties.includes(property.name)}
                            onChange={() => handlePropertyChange(property)} // Handle property selection
                          />
                          {`${property.name} (${property.property_type})`}
                        </label>
                      ))
                  ) : (
                    <p>No properties available for the selected customer</p>
                  )}
                </div>
              </div>
            </>
          )}

          

          {!isSignupSuccessful && (
            <button type="submit">Create User</button>
          )}
        </form>

        {isSignupSuccessful && (
          <>
            <div className="form-overlay"></div>
            <button className="done-button" onClick={onClose}>Done</button>
          </>
        )}

        {showSuccessPopup && (
          <div className="success-popup">
            <p>{success}</p>
          </div>
        )}

        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default SignupForm;
