import React, { useState, useEffect, useRef } from 'react';
import './RemoveUserForm.css';

const RemoveUserForm = ({ customers, onClose }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const dropdownRef = useRef(null);
  const [success, setSuccess] = useState('');
  const [isRemoveSuccessful, setIsRemoveSuccessful] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedCustomer) {
      fetch(`/api/users`)
        .then((response) => response.json())
        .then((data) => {
          const filteredUsers = data.users.filter(user => user.BUILDOPS_CUSTOMER_ID === selectedCustomer.BUILDOPS_CUSTOMER_ID);
          setUsers(filteredUsers || []);
        })
        .catch((error) => {
          console.error('Error fetching users:', error);
          setUsers([]);
        });
    } else {
      setUsers([]);
    }
  }, [selectedCustomer]);
  useEffect(() => {
    console.log("Selected Users Updated: ", selectedUsers);
  }, [selectedUsers]);

  function toProperCase(text) {
    return text.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }


  const handleCustomerChange = (customer) => {
    setSelectedCustomer(customer);
    setDropdownOpen(false);
  };

  const handleUserChange = (userEmail) => {
    setSelectedUsers((prevSelected) => {
      const newSelected = prevSelected.includes(userEmail)
        ? prevSelected.filter((EMAIL) => EMAIL !== userEmail)
        : [...prevSelected, userEmail];
      console.log("After Update: ", newSelected);
      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.EMAIL)); // assuming 'email' is the unique identifier
    }
  };

  const handleRemoveUsers = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!selectedCustomer || selectedUsers.length === 0) {
      console.log('No customer selected or no users selected.');
      return;
    }
  
    try {
      const response = await fetch('/api/users/remove-users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          customer_id: selectedCustomer.BUILDOPS_CUSTOMER_ID,
          users: selectedUsers,
        }),
      });
      setSuccess('Users Removed successfully!');
      setError('');
      setIsRemoveSuccessful(true);
      setShowSuccessPopup(true);
  
      if (response.ok) {
        const result = await response.json();
        console.log('Users removed successfully:', result);
        // Refresh users after removal
        setUsers(users.filter(user => !selectedUsers.includes(user.email)));
        setSelectedUsers([]);
      } else {
        console.error('Failed to remove users:', response.statusText);
      }
    } catch (error) {
      console.error('Error removing users:', error);
    }
  };
  

  useEffect(() => {
    setFilteredCustomers(
      customers.filter((customer) =>
        customer.CUSTOMER_NAME && customer.CUSTOMER_NAME.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, customers]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="overlay">
      <div className={`edit-user-form-modal ${dropdownOpen ? 'dropdown-open' : ''}`}>
        <h2>Remove Users</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleRemoveUsers}>
          <div className="dropdown-container" ref={dropdownRef}>
            <input
              type="text"
              placeholder="Search Customer"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="dropdown-input"
            />
            {dropdownOpen && (
              <div className="dropdown-menu">
                {[...new Set(customers.filter((customer) =>
                  customer.CUSTOMER_NAME && customer.CUSTOMER_NAME.toLowerCase().includes(searchText.toLowerCase())
                ).map(customer => customer.CUSTOMER_NAME))].sort().map(CUSTOMER_NAME => {
                  const customer = customers.find(c => c.CUSTOMER_NAME === CUSTOMER_NAME);
                  return (
                    <div
                      key={customer.BUILDOPS_CUSTOMER_ID}
                      className="dropdown-item"
                      onClick={() => handleCustomerChange(customer)}
                    >
                      {customer.CUSTOMER_NAME}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {selectedCustomer && (
            <div className="users-section">
              <h3>Select Users</h3>
              <div className="users-checkboxes">
                <label className="user-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedUsers.length === users.length}
                    onChange={handleSelectAllChange}
                  />
                  <span className="bold">Select All</span>
                </label>
                
                {users.map((user) => (
                  <label key={user.EMAIL} className="user-checkbox">
                    <input
                      type="checkbox"
                      value={user.EMAIL}
                      checked={selectedUsers.includes(user.EMAIL)}
                      onChange={() => handleUserChange(user.EMAIL)}
                    />
                    <div><span className="bold">{toProperCase(user.NAME)}</span> ({user.EMAIL})</div>
                  </label>
                ))}
              </div>
            </div>
          )}

        {!isRemoveSuccessful && (
            <button type="submit">Remove Users</button>
          )}
        </form>

        {isRemoveSuccessful && (
          <>
            <div className="form-overlay"></div>
            <button className="done-button" onClick={onClose}>Done</button>
          </>
        )}

        {showSuccessPopup && (
          <div className="success-popup">
            <p>{success}</p>
          </div>
        )}
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default RemoveUserForm;
