import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Nav from './Nav';
import SigmaReport from './SigmaReport';
import EditUserForm from './EditUserForm';
//import Filter from './Filter';
import { parseJwt } from '../util/parseJwt';
import './Homepage.css';
import SignupForm from './SignupForm';
import UploadImageForm from './UploadImageForm';
import Menu from './Menu';
import HomeButton from '../assets/images/home.svg';

const Homepage = ({ isLoggedIn, handleLogout }) => {
  const [embedUrls, setEmbedUrls] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showRemoveForm, setShowRemoveForm] = useState(false);
  const [showUploadImageForm, setShowUploadImageForm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  //const [logoUrl, setLogoUrl] = useState('');
  const [currentReport, setCurrentReport] = useState("Dashboard");
  const [navVisible, setNavVisible] = useState(true);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [ecapURL, setEcapURL] = useState(null);
  const [clockworksURL, setClockworksURL] = useState(null);
  const [showEditUserForm, setShowEditUserForm] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = parseJwt(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          console.error('Token expired.');
          handleLogout();
          return;
        }

        setUserDetails(decoded);
        //setLogoUrl(decoded.logoUrl);
        const isAdmin = decoded.email.endsWith('@totalcomfortsolutions.com') || decoded.email.endsWith('@dynamiq.net') || decoded.email.endsWith('@dynamiq.com');
        setIsAdmin(isAdmin);
        const customerId = decoded.userId;
        setEcapURL(decoded.ecapURL);
        setClockworksURL(decoded.clockworksURL);
        axios.get(`${process.env.REACT_APP_API_URL}/api/sigma/buildings/${customerId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
          const properties = response.data;
          setAllProperties(properties);
          setFilteredProperties(properties);
          fetchFilteredEmbedUrl(properties, decoded, initialFilterUrls, setEmbedUrls);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            console.error('Unauthorized: Invalid or expired token.');
          } else {
            console.error('Failed to fetch properties:', error);
          }
        });

        if (isAdmin) {
          axios.get(`${process.env.REACT_APP_API_URL}/api/users/`, {
            headers: { 'Authorization': `Bearer ${token}` }
          })
          .then(response => {
            const nonAdminCustomers = response.data.users.filter(customer => !customer.EMAIL.endsWith('@totalcomfortsolutions.com') && !customer.EMAIL.endsWith('@dynamiq.com') && !customer.EMAIL.endsWith('@dynamiq.net'));
            console.log('Fetched Customers:', nonAdminCustomers);
            setCustomers(nonAdminCustomers);
          })
          .catch(error => {
            console.error('Failed to fetch customers:', error);
          });
        }

      } catch (error) {
        console.error('Failed to parse JWT:', error);
        handleLogout();
      }
    } else {
      console.error('No token found, user is not authorized.');
    }
  }, []);

  useEffect(() => {
    if (currentReport && userDetails.email) {
      const propertiesToUse = filteredProperties.length ? filteredProperties : allProperties;
      fetchFilteredEmbedUrl(propertiesToUse, selectedCustomer || userDetails, reports.find(r => r.name === currentReport).embedded, setEmbedUrls);
    }
  }, [currentReport, userDetails, filteredProperties, allProperties, selectedCustomer]);
  
  const reports = [
    { id: 1, name: 'Dashboard', group: 'General', embedded: [
        'https://app.sigmacomputing.com/embed/1-35aUvxAlRNiXZwsV5hNg2T'
      ] },
    { id: 2, name: 'Quotes', group: 'General', embedded: [
      'https://app.sigmacomputing.com/embed/1-1Z71hnA3movxkJim6zBKpL'
    ] },
    { id: 3, name: 'Work Order', group: 'Work History', embedded: [
      'https://app.sigmacomputing.com/embed/1-4UJL3UoK0JWqt9hcBXKvtu'
    ] },
    { id: 4, name: 'Maintenance', group: 'Work History', embedded: [
      'https://app.sigmacomputing.com/embed/1-1PhJCSwMmReVy5PsniW4Fb',
    ] },
    { id: 5, name: 'Capital Projects', group: 'Work History', embedded: [
      'https://app.sigmacomputing.com/embed/1-25ajUPjBcHse1CuokroeZO',
    ] },
    { id: 6, name: 'Assets', group: 'General', embedded: [
      'https://app.sigmacomputing.com/embed/1-1DtrFU4q5mOMvLJ2KUAS9C'
    ] },
    { id: 7, name: 'Invoicing', group: 'General', embedded: [
      'https://app.sigmacomputing.com/embed/1-2jXHb5uKlbCHnvzz1eiDnr'
    ] },
    { id: 9, name: 'Analytics', group: 'General', embedded: [
      'https://app.sigmacomputing.com/embed/1-2V0ukgjJTjOrUnCLMC5FMa'
    ] },
    { id: 8, name: 'Utilities', group: 'General', embedded: [
      'https://app.sigmacomputing.com/embed/1-6JQBizU7vDZ9xOn5EWxulf'/*ecapUrl*/
    ] },
    
  ];

  const initialFilterUrls = reports[0].embedded;

  const toggleNavVisibility = () => {
    setNavVisible(!navVisible);
  };

  const toggleHeaderVisibility = () => {
    setHeaderVisible(!headerVisible);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleReportSelect = (report) => {
    setCurrentReport(report.name);
    if (userDetails.email) {
      setCurrentReport(report.name); // Set the current report immediately for future reference
      const propertiesToUse = filteredProperties.length ? filteredProperties : allProperties;
      // Since we are depending on the currentReport state that we just set above, we need to ensure it's updated before fetching new URLs
      setEmbedUrls([]); // Optionally reset the URLs immediately
      fetchFilteredEmbedUrl(propertiesToUse, selectedCustomer || userDetails, report.embedded, setEmbedUrls, report.name);
    } else {
      console.error('Email or team not found. User must be logged in.');
    }
  };

  const fetchFilteredEmbedUrl = async (selectedProperties, userDetails, urls, setUrlsCallback, currentReportName) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, authorization denied.');
      setUrlsCallback([]);
      return;
    }
  
    const userId = userDetails.userId || userDetails.BUILDOPS_CUSTOMER_ID;
    const email = userDetails.email || userDetails.EMAIL;
    const decoded = parseJwt(token);
    const internal_email = decoded.email;
  
    if (!userId || !email) {
      console.error('User details incomplete, cannot fetch URLs.');
      setUrlsCallback([]);
      return;
    }
    console.log("fetch filtered selected properties:");
    console.log(selectedProperties.property_names);
    try {
      const payloads = urls.map((url) => ({
        userId,
        email,
        _url: url,
        properties: selectedProperties.property_names,
        selectedReport: currentReport,
        internal_email: internal_email,
      }));
  
      console.log('Payloads:', payloads); // Log the payloads to inspect them
  
      const requests = payloads.map(payload =>
        axios.post(`${process.env.REACT_APP_API_URL}/api/sigma/filtered`, payload, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
      );
  
      const responses = await Promise.all(requests);
      const filteredUrls = responses.map(response => response.data.url);
  
      console.log('Setting Embed URLs State:', filteredUrls);
      setUrlsCallback(filteredUrls);
    } catch (error) {
      console.error('Failed to fetch filtered embed URLs:', error);
      setUrlsCallback([]);
    }
  };
  
  const handleViewAsCustomer = async (customerEmail) => {
    if (!customerEmail) {
      console.log('Clearing selected customer.');
      setSelectedCustomer(null);
      setAllProperties([]);
      setFilteredProperties([]);
      setEmbedUrls([]);
      return;
    }
  
    const token = localStorage.getItem('token');
    const selectedCustomerDetails = customers.find(customer => customer.EMAIL === customerEmail);
    const decoded = parseJwt(token);
    selectedCustomerDetails.internal_email = decoded.email;
  
    if (selectedCustomerDetails) {
      console.log('Updating selected customer:', selectedCustomerDetails);
      setSelectedCustomer({
        ...selectedCustomerDetails,
        userId: selectedCustomerDetails.BUILDOPS_CUSTOMER_ID,
        email: selectedCustomerDetails.EMAIL,
        internal_email: decoded.email,
      });
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sigma/buildings/${selectedCustomerDetails.BUILDOPS_CUSTOMER_ID}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const customerProperties = response.data;
        setAllProperties(customerProperties);
        setFilteredProperties(customerProperties);
        fetchFilteredEmbedUrl(customerProperties, selectedCustomerDetails, initialFilterUrls, setEmbedUrls);
      } catch (error) {
        console.error('Failed to fetch customer properties:', error);
      }
    } else {
      console.error('Selected customer details not found.');
      setSelectedCustomer(null);
    }
  };

  const handleSignupFormClose = () => {
    setShowSignupForm(false);
  };

  const handleEditFormClose = () => {
    setShowEditUserForm(false);
  };

  const handleUploadImageFormClose = () => {
    setShowUploadImageForm(false);
  };

  const handleCustomerNameClick = () => {
    setMenuOpen(!menuOpen);  // Toggle the menu state
  };

  const navigateToDashboard = () => {
    console.log("Attempting to navigate to dashboard");
  
    // Determine the target user details based on the admin status
    const targetUserDetails = isAdmin && selectedCustomer ? selectedCustomer : userDetails;
  
    if (!targetUserDetails || !targetUserDetails.userId || !targetUserDetails.email) {
      console.error('User details incomplete:', targetUserDetails);
      setEmbedUrls([]); // Optionally clear existing URLs as there's an error
      return;  // Handle this situation appropriately, possibly with UI feedback
    }
  
    const propertiesToUse = filteredProperties.length ? filteredProperties : allProperties;
    fetchFilteredEmbedUrl(propertiesToUse, targetUserDetails, initialFilterUrls, setEmbedUrls);
    setCurrentReport("Dashboard");
  };
  
  // Function to render content conditionally
  const renderContent = () => {
    // Check if admin has not selected a customer view
    if (isAdmin && !selectedCustomer) {
      return (
        <div className="admin-view-selection">
          <h2>Select a customer to view reports</h2>
        </div>
      );
    }

    if (!embedUrls.length) {
      console.log('No embed URLs to render');
      return <p className='report-load'>Loading reports</p>;
    }
  
    console.log('Rendering reports with URLs:', embedUrls);
    return (
      <div className="reports-grid">
        {embedUrls.map((url, index) => (
          <SigmaReport key={index} embedUrl={url} />
        ))}
      </div>
    );
  };

  function toProperCase(text) {
    if (typeof text !== 'string') {
      console.error('Invalid input: Expected a string');
      return '';
    }
    return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
  

  return (
    <div className="homepage-wrapper">
      {/*<button onClick={toggleNavVisibility} className={`nav-toggle-button ${navVisible ? 'nav-visible' : 'nav-hidden'}`}>*/}
        {/* Use CSS to create the arrow, no need to change the rendering logic */}
      {/*</button>*/}
  
      <div className={`report-list-container ${navVisible ? 'nav-visible' : 'nav-hidden'}`}>
        <Nav
          reports={reports}
          handleReportSelect={handleReportSelect}
          isLoggedIn={isLoggedIn}
          handleLogout={handleLogout}
          navigateToDashboard={navigateToDashboard}
        />
      </div>
  
      <div className="homepage-content">
        <div className="header">
          
          {/*<Filter
            properties={allProperties}
            onFilterChange={handleFilterChange}
          />*/}
          <div className="left-buttons-container">
            <button onClick={navigateToDashboard} className="home-button">
              <img src={HomeButton} alt="Home" />
            </button>
  
            {currentReport && <span className="selected-report-name">{currentReport}</span>}
          </div>
          {/* Admin controls */}
          {isAdmin && (
            <div>
  
              <div className={`admin-controls`}>
                <span className="admin-title">Admin</span>
                <button className="add-user-button" onClick={() => setShowSignupForm(true)}>Add User</button>
                {/*<button className="upload-image-button" onClick={() => setShowUploadImageForm(true)}>Upload Image</button>*/}
                <select className="customer-list" onChange={(e) => handleViewAsCustomer(e.target.value)}>
                  <option value="">Select Customer View</option>
                  {customers.length > 0 ? (
                    [...new Set(customers.filter(customer => customer.EMAIL && customer.CUSTOMER_NAME)  // Filter out null values
                      .map(customer => customer.CUSTOMER_NAME))] // Extract names and eliminate duplicates
                      .sort() // Sort alphabetically
                      .map((name, index) => {
                        const customer = customers.find(c => c.CUSTOMER_NAME === name); // Find original customer object by name
                        return <option key={index} value={customer.EMAIL}>{name}</option>;
                      })
                  ) : (
                    <option value="" disabled>No customers available</option>
                  )}
                </select>
                <button className="add-user-button" onClick={() => setShowEditUserForm(true)}>Edit User</button>
                {/*{showRemoveUserForm && (
                  <RemoveUserForm customers={customers} onClose={handleRemoveFormClose}/>
                )}*/}
                {showEditUserForm && (
                  <EditUserForm customers={customers} onClose={handleEditFormClose}/>
                )}
              </div>
            </div>
          )}
          <div className="controls-container">
            <p className="customer-name" onClick={handleCustomerNameClick}>
              {toProperCase(userDetails.customerName)} |
            </p>
            <Menu handleLogout={handleLogout} username={toProperCase(userDetails.name)} externalToggle={menuOpen}/>
            {/*<img src={logoUrl} alt="Logo" className="logo" />*/}
          </div>
        </div>
  
        {/* Render signup form if showSignupForm is true */}
        {showSignupForm && <SignupForm onClose={handleSignupFormClose} />}
  
        {/* Render upload image form if showUploadImageForm is true */}
        {showUploadImageForm && <UploadImageForm customers={customers} onClose={handleUploadImageFormClose} />}
  
        {renderContent()}
      </div>
    </div>
  );  
};

export default Homepage;
