import React, { useState } from 'react';
import axios from 'axios';
import './UploadImageForm.css';

const UploadImageForm = ({ customers, onClose }) => {
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
    setErrorMessage('');
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedCustomer || !selectedFile) {
        setErrorMessage('Customer and file must be selected.');
        return;
    }

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('customer', selectedCustomer);

    try {
      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/s3/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      const s3Url = uploadResponse.data.url;

      await axios.post(`${process.env.REACT_APP_API_URL}/api/s3/update-customer-image`, {
        customer: selectedCustomer,
        imageUrl: s3Url
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      setSuccessMessage('Image uploaded and customer updated successfully.');
      onClose();
    } catch (error) {
      console.error('Failed to upload image and update customer:', error);
      setErrorMessage('Failed to upload image and update customer.');
    }
  };

  return (
    <div className="overlay">
      <div className="upload-image-form">
        <h2>Upload Customer Image</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <label>
            Select Customer:
            <select value={selectedCustomer} onChange={handleCustomerChange}>
              <option value="">Select Customer</option>
              {customers.map((customer, index) => (
                <option key={index} value={customer.EMAIL}>{customer.CUSTOMER_NAME}</option>
              ))}
            </select>
          </label>
          <label>
            Select Image:
            <input type="file" onChange={handleFileChange} />
          </label>
          <button type="submit">Upload</button>
          <button className="close-button" onClick={onClose}>×</button>
        </form>
      </div>
    </div>
  );
};

export default UploadImageForm;
