import React, { useState, useEffect, useRef } from 'react';
import './EditUserForm.css';
import RemoveUserForm from './RemoveUserForm';

const EditUserForm = ({ customers, onClose }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [properties, setProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedPropertyNames, setSelectedPropertyNames] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const dropdownRef = useRef(null);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [showRemoveUserForm, setShowRemoveUserForm] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    // This effect handles both fetching new users and resetting user fields when the customer changes.
    if (selectedCustomer) {
      console.log('Fetching users for selected customer:', selectedCustomer);
      fetch(`/api/users`)
        .then(response => response.json())
        .then(data => {
          const filteredUsers = data.users.filter(user => user.BUILDOPS_CUSTOMER_ID === selectedCustomer.BUILDOPS_CUSTOMER_ID);
          console.log('Fetched Users:', filteredUsers);
          setUsers(filteredUsers);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
          setUsers([]); // Ensure users are cleared on error
        });
      // Reset user details
      setSelectedUser(null);
      setProperties([]);
      setSelectedProperties([]);
      setSelectedPropertyNames([]);
      setName('');
      setEmail('');
      setError('');
      setSuccess('');
    } else {
      setUsers([]);
      setSelectedUser(null);
      setProperties([]);
      setSelectedPropertyNames([]);
      setName('');
      setEmail('');
      setError('');
      setSuccess('');
    }
  }, [selectedCustomer]); 

  useEffect(() => {
    if (selectedUser && selectedCustomer) {
      const token = localStorage.getItem('token');
      Promise.all([
        fetch(`/api/sigma/buildings/${encodeURIComponent(selectedCustomer.BUILDOPS_CUSTOMER_ID)}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(res => res.json()),
        fetch(`/api/users/getproperty/${encodeURIComponent(selectedUser.EMAIL)}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(res => res.json())
      ]).then(([allProperties, userPropertiesResponse]) => {
        console.log('All Properties:', allProperties);
        console.log('User Properties:', userPropertiesResponse);
  
        const transformedProperties = allProperties.property_ids.map((id, index) => ({
          id: id,
          name: allProperties.property_names[index],
          type: allProperties.property_type[index]
        }));
        setProperties(transformedProperties);
  
        const userProps = userPropertiesResponse.user.length > 0 ? userPropertiesResponse.user[0].PROPERTIES : [];
        const selectedIds = transformedProperties.filter(prop => userProps.includes(prop.name)).map(prop => prop.id);
        setSelectedProperties(transformedProperties.filter(prop => userProps.includes(prop.name)));
        const selectedNames = transformedProperties.filter(prop => userProps.includes(prop.name)).map(prop => prop.name);
        setSelectedPropertyNames(selectedNames);
  
        console.log('Rendering edit fields for user:', selectedUser);
        setName(selectedUser.NAME);
        setEmail(selectedUser.EMAIL);
        setOriginalEmail(selectedUser.EMAIL);
      }).catch(error => console.error('Error fetching properties:', error));
    } else {
      setProperties([]);
      setSelectedProperties([]);
      setSelectedPropertyNames([]);
      setName('');
      setEmail('');
      setOriginalEmail('');
    }
  }, [selectedUser, selectedCustomer]);
  
  

  useEffect(() => {
    const uniqueCustomers = customers
      .filter(customer => customer.CUSTOMER_NAME && customer.BUILDOPS_CUSTOMER_ID) // Filter out null or undefined customer names or IDs
      .reduce((acc, current) => {
        const x = acc.find(item => item.CUSTOMER_NAME === current.CUSTOMER_NAME);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []) // Remove duplicates based on customer name
      .sort((a, b) => a.CUSTOMER_NAME.localeCompare(b.CUSTOMER_NAME)); // Sort alphabetically by customer name
  
    setFilteredCustomers(uniqueCustomers);
  }, [customers]);

  const handleUserChange = (e) => {
    const selectedId = e.target.value;
    const user = users.find(user => `${user.NAME}`.toLowerCase() === selectedId);
    setSelectedUser(user);
  };

  const handleRemoveFormClose = () => {
    setShowRemoveUserForm(false);
  };

  const handlePropertyChange = (propertyId, propertyName) => {
    setSelectedProperties((prev) => {
      // Find if the property is already selected
      const propertyExists = prev.some((prop) => prop.id === propertyId);
      
      // Create a new selected properties array
      const newSelectedProperties = propertyExists
        ? prev.filter((prop) => prop.id !== propertyId) // Deselect the property
        : [...prev, { id: propertyId, name: propertyName }]; // Select the property with id and name
  
      // Update selected property names separately for clarity
      const newSelectedPropertyNames = newSelectedProperties.map(prop => prop.name);
      setSelectedPropertyNames(newSelectedPropertyNames);
  
      // Check if all properties are selected
      setSelectAll(newSelectedProperties.length === properties.length);
  
      return newSelectedProperties;
    });
  };
  

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('/api/users/changeproperties', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_email: selectedUser.EMAIL,
          properties: selectedProperties,
          property_names: selectedPropertyNames,
          new_name: name,
          new_email: email,
        }),
      });
  
      if (response.ok) {
        if (email !== originalEmail) {
          // Send POST request to send the welcome email to the new email
          fetch('/api/users/welcomeemail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ new_email: email })
          })
          .then(res => {
            if (res.ok) {
              console.log('Welcome email sent to new email:', email);
            } else {
              console.error('Failed to send welcome email:', res.statusText);
            }
          })
          .catch(err => console.error('Error sending welcome email:', err));
        }
        const updatedUser = await response.json();
        console.log('User details updated successfully:', updatedUser);
  
        // Update users array with the updated user details
        setUsers(prevUsers => prevUsers.map(user => 
          user.EMAIL === selectedUser.EMAIL ? { ...user, NAME: updatedUser.user.NAME, EMAIL: updatedUser.user.EMAIL } : user
        ));
  
        // Update selectedUser with new details
        setSelectedUser(prevSelected => ({
          ...prevSelected,
          NAME: updatedUser.user.NAME,
          EMAIL: updatedUser.user.EMAIL
        }));
  
        setSuccess('User details updated successfully!');
        setError('');
        setShowSuccessPopup(true);
        setIsUpdateSuccessful(true); // Set to true to show the Done button
        setTimeout(() => setShowSuccessPopup(false), 3000); // Optional: Auto-hide popup
      } else {
        throw new Error(`Failed to update user details: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error updating user details:', error);
      setError('Failed to update user details.');
      setIsUpdateSuccessful(false); // Ensure it's set to false on failure
    }
  };
  
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function toProperCase(text) {
    if (typeof text !== 'string') {
      console.error('Invalid input: Expected a string');
      return '';
    }
    return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all properties
      setSelectedProperties([]);
      setSelectedPropertyNames([]);
    } else {
      // Select all properties
      const allSelectedProperties = properties.map(property => ({
        id: property.id,
        name: property.name,
      }));
      setSelectedProperties(allSelectedProperties);
      setSelectedPropertyNames(properties.map(property => property.name));
    }
    setSelectAll(!selectAll); // Toggle the select all state
  };

  return (
    <div className="overlay">
      <div className={`edit-user-form-modal ${dropdownOpen ? 'dropdown-open' : ''}`}>
        <button className="add-user-button" onClick={() => setShowRemoveUserForm(true)}>Remove User Form</button>
        {showRemoveUserForm && (
          <RemoveUserForm customers={customers} onClose={handleRemoveFormClose} />
        )}
        <h2>Edit User</h2>
        {error && <p className="error">{error}</p>}
        {/*success && <p className="success">{success}</p>*/}
        <form onSubmit={handleSaveChanges}>
          <div className="dropdown-container" ref={dropdownRef}>
            <input
              type="text"
              placeholder="Search Customer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
              readOnly
              value={selectedCustomer ? selectedCustomer.CUSTOMER_NAME : 'Select Customer'}
              className="dropdown-input"
            />
            {dropdownOpen && (
              <div className="dropdown-menu">
                {filteredCustomers.map(customer => (
                  <div
                    key={customer.BUILDOPS_CUSTOMER_ID}
                    className="dropdown-item"
                    onClick={() => {
                      setSelectedCustomer(customer);
                      setDropdownOpen(false);
                    }}
                  >
                    {customer.CUSTOMER_NAME}
                  </div>
                ))}
              </div>
            )}
          </div>

          {selectedCustomer && (
             <div className="user-select">
             {/* Adding key prop to reset the select dropdown when selectedCustomer changes */}
             <select 
               key={selectedCustomer ? selectedCustomer.BUILDOPS_CUSTOMER_ID : 'none'}
               onChange={(e) => {
                 const selectedId = e.target.value;
                 const user = users.find(user => `${user.NAME}`.toLowerCase() === selectedId);
                 setSelectedUser(user);
               }}
             >
               <option value="">Select User</option>
               {users.map(user => (
                 <option key={user.id} value={user.NAME.toLowerCase()}>{toProperCase(user.NAME)}</option>
               ))}
             </select>
           </div>
          )}

          {selectedUser && (
            <>
              <div className="edit-fields">
                <label>
                  <strong>Name:</strong>
                  <input 
                    type="text" 
                    value={toProperCase(name)} 
                    onChange={(e) => setName(e.target.value)} 
                  />
                </label>
                <label>
                  <strong>Email:</strong>
                  <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                  />
                </label>
              </div>
              <h4 className='properties-title'>Properties</h4>
              <div className="properties-section">
                {/* Select All Checkbox */}
                <div className="property-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                    Select All
                  </label>
                </div>
                {properties
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(property => (
                  <div key={property.id} className="property-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedProperties.some(prop => prop.id === property.id)} // Check if property is selected
                        onChange={() => handlePropertyChange(property.id, property.name)}
                      />
                      {property.name}
                    </label>
                  </div>
                ))}
              </div>

              {!isUpdateSuccessful && (
                <button type="submit">Submit Edits</button>
              )}
              
              
            </>
          )}
          
        </form>
        {isUpdateSuccessful && (
                <>
                  <div className="form-overlay"></div>
                  <button className="done-button" onClick={onClose}>Done</button>
                </>
              )}

              {showSuccessPopup && (
                <div className="success-popup">
                  <p>{success}</p>
                </div>
              )}
              <button className="close-button" onClick={onClose}>×</button>
        
      </div>
    </div>
  );
};


export default EditUserForm;
